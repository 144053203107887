import React, { useState} from 'react'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import Fab from '@mui/material/Fab'
import Papa from 'papaparse'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Alert from '@mui/material/Alert'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import ProgressFeedBack from './ProgressFeedBack/ProgressFeedBack'

const CsvImport = () => {
  const [openError, setOpenError] = useState(false)
  const [listaDeObjetos, setListaDeObjetos] = useState([])
  const [progressFeedbackVisible, setProgressFeedbackVisible] = useState(false)

  const changeHandler = event => {
    // Passing file data (event.target.files[0]) to parse using Papa.parse
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const listaDeObjetosSanitized = []
        results.data.forEach(element => {
          if (
            !(
              element["ID"] &&
              element["CPF/CNPJ do Beneficiário"]
            )
          ) {
            setOpenError(true)
            return
          } else {
            const isLead = element.hasOwnProperty('Lead Name')
            const objeto = {
                leadsId: element.ID,
                cpfs: [element['CPF/CNPJ do Beneficiário']],
                isLead: isLead
            }
            listaDeObjetosSanitized.push(objeto)
            console.log(element)
          }
          setListaDeObjetos(listaDeObjetosSanitized)
          setProgressFeedbackVisible(true)
        })
      }
    })
  }
  return (
    <Box
      sx={{
        display: 'grid',
        flexDirection: 'column',
        alignItems: 'center',
        width: '30vw',
        height: '30vh',
        padding: '10px'
      }}
    >
      <Typography variant='h5' component='div' sx={{
        marginBottom: '10px'
      }}>
        Importe um arquivo .csv contendo os dados de leads para sincronizar os
        contatos dos beneficiários.
      </Typography>
      <Collapse in={progressFeedbackVisible}>
        <ProgressFeedBack
          listaDeObjetos={listaDeObjetos}
          setProgressVisible={setProgressFeedbackVisible}
          setListaDeObjetos={setListaDeObjetos}
        />
      </Collapse>

      <Box sx={{ width: '100%' }}>
        <Collapse in={openError}>
          <Alert
            severity='error'
            action={
              <IconButton
                aria-label='close'
                color='inherit'
                size='small'
                onClick={() => {
                  setOpenError(false)
                }}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            O arquivo .csv não está no formato correto.
          </Alert>
        </Collapse>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          width: '100%',
          height: '20%',
          justifyContent: 'flex-end',
          justifySelf: 'flex-end',
          alignSelf: 'flex-end'
        }}
      >
        <label htmlFor='upload-photo'>
          <input
            style={{ display: 'none', bottom: 16, right: 16 }}
            id='upload-photo'
            name='upload-photo'
            type='file'
            onChange={changeHandler}
            accept='.csv'
          />
          <Fab
            sx={{
              position: 'relative',
              bottom: 16,
              right: 16
            }}
            color='primary'
            size='small'
            component='span'
            aria-label='add'
            variant='extended'
          >
            <AddIcon /> Importar CSV
          </Fab>
        </label>
      </Box>
    </Box>
  )
}

export default CsvImport
