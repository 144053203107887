import React, { useState, useEffect } from 'react'
import { test } from '../../interfaces/interface'
import {
  getCpfCnpjFromInfo,
  getCardIdFromInfo,
  isTheCardDeal,
  isTheCardLead,
} from '../../services/bitrixEnviromentInterface'
import { updateContatos, updateContactsOnDeal, updateById } from '../../interfaces/interface'
import BotaoFeedBack from './BotaoFeedBack/BotaoFeedBack'
import Box from '@mui/material/Box'
import image from './logo1.webp'

const IntegracaoCartao = () => {
  const [time, setTime] = useState(0)
  const [status, setStatus] = useState('NONE')

  useEffect(() => {
    async function fetch () {
      const time = await test()

      setTime(time.data.serverTime)
    }
    fetch()
  }, [])

  async function fetch () {
    if (await isTheCardDeal()) {
      await fetchFromDeal()
    }
    if (await isTheCardLead()) {
      await fetchFromLead()
    }
  }

async function fetchFromDeal () {
  setStatus('LOADING')
  const cpfCnpj = await getCpfCnpjFromInfo()
  const cardId = await getCardIdFromInfo()
  let cpfs = []
  cpfs.push(cpfCnpj)
  const response = await updateContactsOnDeal(cpfs, cardId)
  setStatus('OK')
  console.log(response)
  console.log(cpfCnpj)
}

  async function fetchFromLead () {
    setStatus('LOADING')
    const cpfCnpj = await getCpfCnpjFromInfo()
    const cardId = await getCardIdFromInfo()
    let cpfs = []
    cpfs.push(cpfCnpj)
    const response = await updateContatos(cpfs, cardId)
    setStatus('OK')
    console.log(response)
    console.log(cpfCnpj)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
        height: '90vh',
      }}
      data-testid='body'
      id='body'
    >
      <Box
        sx={{
          width: '90%'
        }}
        data-testid='title'
        id='title'
      >
        <img src={image} alt='logo' height={100} width={250} />
      </Box>
      <BotaoFeedBack onClickFunction={fetch} status={status} />
    </Box>
  )
}

export default IntegracaoCartao
