import React, { useState, useEffect } from 'react'
import { User } from '../../domain/User'
import { Navigate } from 'react-router-dom'
import image from './logo1.webp'
import Button from '@mui/material/Button'
import AppBar from '@mui/material/AppBar'
import CardContent from '@mui/material/CardContent'
import CircularProgress from '@mui/material/CircularProgress'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { install, unistall } from '../../services/bitrixEnviromentInterface'
import CsvImport from './CsvImport/CsvImport'

const Logged = () => {
  const [user, setUser] = useState()
  const [logOutStatus, setLogOutStatus] = useState()
  const [logged, setLogged] = useState('NONE')

  useEffect(() => {
    async function fetch () {
      const user = new User()
      const me = await user.getIdentity()
      setUser(me.data)
      setLogged(me.sucess)
    }
    fetch()
  }, [])
  useEffect(() => {
    async function fetch () {
      await unistall()
      const installation = await install()
      if (installation) {
        console.log('instalado')
      } else {
        const installation = await install()
        if (installation) {
          console.log('instalado')
        }
      }
    }
    fetch()
  }, [])

  async function logMeOut () {
    setLogOutStatus('NONE')
    const user = new User()
    const logOut = await user.logUserOut()
    if (logOut.sucess) {
      setLogOutStatus('OK')
      setLogged(false)
    }
  }

  return logged == false ? (
    <Navigate to={'/'} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'center'
      }}
      id='body'
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '1%'
        }}
        data-testid='mainApp'
        id='mainApp'
      >
        <AppBar
          sx={{
            backgroundColor: '#e8e8e8'
          }}
          position='static'
        >
          {user ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <CardContent
                sx={{
                  maxWidth: '70%',
                  overflow: 'scroll'
                }}
              >
                <Box
                  sx={{
                    width: '90%'
                  }}
                  data-testid='title'
                  id='title'
                >
                  <img src={image} alt='logo' height={100} width={250} />
                </Box>
              </CardContent>
              {logOutStatus == 'NONE' ? (
                <CircularProgress color='inherit' sx={{ margin: 3 }} />
              ) : (
                <Button
                  sx={{
                    margin: 3,
                    color: 'black'
                  }}
                  onClick={() => {
                    logMeOut()
                  }}
                >
                  Logout
                </Button>
              )}
            </Box>
          ) : (
            <></>
          )}
        </AppBar>
        <Box
          sx={{
            display: 'flex',
            borderRadius: '2rem',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '1%'
          }}
        >
          <Box
            sx={{
              padding: '20px',
              borderRadius: '2rem'
            }}
            data-testid='appHeader'
            id='appHeader'
          >
            <Typography variant='h6' component='div' color='grey'>
              Bem vindo à integração Bitrix
            </Typography>
            <Typography variant='h5' component='div'>
              {user}
            </Typography>
            <CsvImport />
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default Logged
