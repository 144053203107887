import React, { useState, useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

import { updateContatos, updateContactsOnDeal } from '../../../../interfaces/interface'

const ProgressFeedBack = ({
  listaDeObjetos,
  setProgressVisible,
  setListaDeObjetos
}) => {
  const possibleStates = {
    NONE: 'NONE',
    SUCESS: 'SUCESS',
    ERROR: 'ERROR',
    PENDING: 'PENDING'
  }
  const [state, setState] = useState(possibleStates.NONE)
  const [progress, setProgress] = useState(0)
  const [total, setTotal] = useState(0)
  const [erros, setErros] = useState(0)

  useEffect(() => {
    //Feedback about the length of the list
    console.log(listaDeObjetos)
    setTotal(listaDeObjetos.length)
  }, [listaDeObjetos])

  async function updateContatosProgress () {
    setState(possibleStates.PENDING)
    //Fetches the endpoint for every object in the list, one after the other asynchronously
    for (const objeto of listaDeObjetos) {
      try {

        const response =  objeto.isLead ?
        await updateContatos(objeto.cpfs, objeto.leadsId) :
        await updateContactsOnDeal(objeto.cpfs, objeto.leadsId)

        if (response.status == 200) {
          setProgress(progress => progress + 1)
        } else {
          setErros(erros => erros + 1)
        }
      } catch (error) {
        console.log(error)
        setErros(erros => erros + 1)
      }
    }
    setState(possibleStates.SUCESS)
  }

  function closeProgress () {
    setProgress(0)
    setState(possibleStates.NONE)
    setListaDeObjetos([])
    setProgressVisible(false)
    setErros(0)
  }

  return (
    //DIsplays different UI depending on the state

    state == possibleStates.NONE ? (
      <Paper elevation={3}>
        <Box p={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px'
            }}
        >
          <Typography variant='h6' component='div'>
            Importação de contatos
          </Typography>
          <Typography variant='subtitle1' component='div'>
            {`Progresso: ${progress}/${total}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >

          <Button variant='contained' onClick={updateContatosProgress}>
            Iniciar importação
          </Button>
          <Button  onClick={() => closeProgress()}>
            Cancelar
          </Button>
          </Box>
        </Box>
      </Paper>
    ) : state == possibleStates.PENDING ? (
      <Paper elevation={3}>
        <Box p={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px'
            }}
        >
          <Typography variant='h6' component='div'>
            Importação de contatos
          </Typography>
          <Typography variant='subtitle1' component='div'>
            {`Progresso: ${progress}/${total}`}
          </Typography>
        </Box>
      </Paper>
    ) : state == possibleStates.SUCESS ? (
      <Paper elevation={3}>
        <Box p={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px'
            }}
        >
          <Typography variant='h6' component='div'>
            Importação de contatos
          </Typography>
          <Typography variant='subtitle1' component='div'>
            {`Contatos Sincronizados: ${progress}/${total}`}
          </Typography>
          <Typography variant='subtitle1' component='div'>
            {`Não Localizados: ${erros}`}
          </Typography>
          <Typography variant='subtitle1' component='div'>
            Importação concluída!
          </Typography>
        <Button variant='contained' onClick={() => closeProgress()}>
          Fechar
        </Button>
        </Box>
      </Paper>
    ) : (
      <Paper elevation={3}>
        <Box p={2}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                padding: '10px'
            }}
        >
          <Typography variant='h6' component='div'>
            Importação de contatos
          </Typography>
          <Typography variant='subtitle1' component='div'>
            {`Progresso: ${progress}/${total}`}
          </Typography>
          <Typography variant='subtitle1' component='div'>
            Erro na importação
          </Typography>
        <Button variant='contained' onClick={() => setProgressVisible(false)}>
          Fechar
        </Button>
        </Box>
      </Paper>
    )
  )
}

export default ProgressFeedBack
