import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Main from './app/components/Main/Main'
import IntegracaoCartao from './app/components/IntegracaoCartao/IntegracaoCartao'
import Login from './app/components/Login/Login'
import Logged from './app/components/Logged/Logged'




const router = createBrowserRouter([
  {
    path: '/',
    element: < Login/>
  },
  {
    path: '/user',
    element: < Logged/>
  },
  {
    path: '/integracaocartao',
    element: < IntegracaoCartao/>

  }
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
